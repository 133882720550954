#admin-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.75rem;
}

#admin-state-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1.75rem;
}