footer {
    position: absolute;
    bottom: 0;

    text-align: center;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column; 
}