/* 
 * Base Profile 
 */

.App {
  height: 100vh;
  width: 100vw;
  max-width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: #ffffff;
  background-color: #0e0d0e;
  overflow: hidden;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
}

main {
  text-align: center;
}

.filler-vertical {
  height: 100%;
}

.filler-horizontal {
  width: 100%;
}

.center-vertical {
  display: flex;
  align-items: center;
}

a {
  color: #fff;
}

button, input[type="submit"] {
  cursor: pointer;
  padding: 1rem 0.75rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  border: 2px solid #ffffff;
  transition: 0.25s ease;
}

:hover:is(button, input[type="submit"]) {
  color: #ffffff;
  background-color: #0e0d0e;
}

:active:is(button, input[type="submit"]) {
  transform: scale(95%);
}

input {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
}

input[type="submit"] {
  font-weight: 700;
  font-variant: small-caps;
}

input[type="submit"]:hover {
  background-color: #0e0d0e;
  color: #ffffff;
}

#copyright {
  margin-bottom: 1.5rem;
}

/* 
 * Custom 
 */

#main-question {
  /* font-size: 5em; */
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.sven-status {
  /* font-size: 3em; */
  font-size: 1.75rem;
  font-weight: 300;
}

#next-event-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}