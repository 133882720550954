.arrow {
    font-size: 2.5rem;
    color: white;
    text-decoration: none;
}

.arrow:hover {
    transform: translateX(-50%);
}

#admin-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

#admin-buttons > * {
    width: 100%;
}

#admin-buttons button {
    width: 100%;
    text-transform: capitalize;
    font-weight: 800;
}

#admin-state-buttons {
    display: flex;
    justify-content: space-between;
}