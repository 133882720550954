.gasquen-event {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
  
    padding: 1.5rem 1.5rem 0.875rem 1.5rem;
  
    color: #0e0d0e;
    font-weight: 500;
    background-color: #ffffff;
    text-decoration: none;
}

.gasquen-event > * {
    margin: 0
}